import React, { Component } from "react";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import FaultTracker from "../../images/fault_tracker.png"
import EasyAnalytics from "../../images/easy_analytics.png"
import RewardBook from "../../images/reward_book.png"
import MetalClock from "../../images/metal_clock.png"

class Topbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      page: false,
      landing: false,
      docs: false,
      new: false,
      utility: false,
      user: false,
      work: false,
      blog: false,
      carr: false
    };
    this.toggleLine = this.toggleLine.bind(this);
  }

  toggleLine() {
    this.setState(prevState => ({ isOpen: !prevState.isOpen }));
  }

  componentDidMount() {
    var matchingMenuItem = null;
    var ul = document.getElementById("top-menu");
    var items = ul.getElementsByTagName("a");
    for (var i = 0; i < items.length; ++i) {
      if (this.props.location.pathname === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      this.activateParentDropdown(matchingMenuItem);
    }
  }

  activateParentDropdown = item => {
    const parent = item.parentElement;
    if (parent) {
      parent.classList.add("active"); // li
      const parent1 = parent.parentElement;
      parent1.classList.add("active"); // li
      if (parent1) {
        const parent2 = parent1.parentElement;
        parent2.classList.add("active"); // li
        if (parent2) {
          const parent3 = parent2.parentElement;
          parent3.classList.add("active"); // li
          if (parent3) {
            const parent4 = parent3.parentElement;
            parent4.classList.add("active"); // li
          }
        }
      }
    }
  };

  render() {
    const path = this.props.location.pathname;
    return (
      <React.Fragment>
        <header id="topnav" className="defaultscroll sticky" style={{backgroundColor:"#fffafac4"}}> 
          <div className="container">
            <div>
                {(() => {
                  switch (path) {
                    case "/easyanalytics":
                      return  <Link className="logo" to="/easyanalytics"><img src={EasyAnalytics} alt="" style={{height:"80px"}} />EasyAnalytics</Link>
                    case "/easyanalytics/":
                        return <Link className="logo" to="/easyanalytics"><img src={EasyAnalytics} alt="" style={{height:"80px"}}/>EasyAnalytics</Link>
                    case "/faulttracker":
                      return <Link className="logo" to="/faulttracker"><img src={FaultTracker} alt="" style={{height:"50px"}} /> FaultTracker </Link>
                    case "/faulttracker/":
                        return <Link className="logo" to="/faulttracker"><img src={FaultTracker} alt="" style={{height:"50px"}} /> FaultTracker</Link>
                    case "/rewardbook":
                      return <Link className="logo" to="/rewardbook/"><img src={RewardBook} alt="" style={{height:"50px"}} /> RewardBook</Link>
                    case "/rewardbook/":
                        return <Link className="logo" to="/rewardbook/"><img src={RewardBook} alt="" style={{height:"50px"}} /> RewardBook</Link>    
                    default:
                      return <Link className="logo" to="/"><img src={MetalClock} alt="" style={{height:"50px"}} /> MetalClock</Link>
                  }
                })()}
            </div>
            <div className="menu-extras">
              <div className="menu-item">
                <Link
                  to="#"
                  onClick={this.toggleLine}
                  className={
                    this.state.isOpen ? "navbar-toggle open" : "navbar-toggle"
                  }
                >
                  <div className="lines">
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                </Link>
              </div>
            </div>

            <div
              id="navigation"
              style={{ display: this.state.isOpen ? "block" : "none" }}
            >
              <ul
                className="navigation-menu"
                id="top-menu"
                style={{ justifyContent: "flex-end", paddingRight: "30px" }}
              >
                <li>
                 <Link to="/" style={{color:"#2f55d4"}}>Home</Link>
                </li>
                <li className="has-submenu">
                  <Link
                    to="/#"
                    onClick={event => {
                      event.preventDefault();
                      this.setState({ landing: !this.state.landing });
                    }}
                  >
                    Products
                  </Link>
                  <span className="menu-arrow"></span>
                  <ul
                    className={
                      this.state.landing
                        ? "submenu megamenu open"
                        : "submenu megamenu"
                    }
                  >
                    <li>
                      <ul style={{}}>
                        <li>
                          <Link to="/easyanalytics">EasyAnalytics</Link>
                        </li>
                        <li>
                          <Link to="/faulttracker">FaultTracker</Link>
                        </li>
                        <li>
                          <Link to="/rewardbook">RewardBook</Link>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
                <li>
                 <Link to="/why_metalclock" style={{color:"#2f55d4"}}>Why MetalClock?</Link>
                </li>
                <li>
                 <Link to="/aboutus" style={{color:"#2f55d4"}}>AboutUs</Link>
                </li>
                <li>
                 <Link to="/contactus" style={{color:"#2f55d4"}}>ContactUs</Link>
                </li>
              </ul>
            </div>
          </div>
        </header>
      </React.Fragment>
    );
  }
}

export default withRouter(Topbar);
