import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Footer extends Component {

  constructor(props) {
    super(props);
    this.state = { };
  }

  render() {
    return (
      <React.Fragment>
     <footer className="footer">
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 col-12 mb-0 mb-md-4 pb-0 pb-md-2">
                        <Link className="logo-footer" to="#">MetalClock</Link>
                        <p className="mt-4"></p>
                        <ul className="list-unstyled social-icon social mb-0 mt-4">
                            <li className="list-inline-item"><a href="https://www.linkedin.com/company/eparchi/about/" className="rounded mr-1"><i className="mdi mdi-linkedin" title="Linkdin"></i></a></li>
                            {/* <li className="list-inline-item"><Link to="#" className="rounded mr-1"><i className="mdi mdi-instagram" title="Instagram"></i></Link></li> */}
                            {/* <li className="list-inline-item"><Link to="#" className="rounded"><i className="mdi mdi-twitter" title="Twitter"></i></Link></li> */}
                        </ul>
                    </div>

                    <div className="col-lg-2 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                        <h4 className="text-light footer-head">Company</h4>
                        <ul className="list-unstyled footer-list mt-4">
                            <li><Link to="/aboutus" className="text-foot"><i className="mdi mdi-chevron-right mr-1"></i> About us</Link></li>
                            <li><Link to="/aboutus" className="text-foot"><i className="mdi mdi-chevron-right mr-1"></i> Why Metalclock?</Link></li>
                        </ul>
                    </div>

                    <div className="col-lg-3 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                        <h4 className="text-light footer-head">Our products</h4>
                        <ul className="list-unstyled footer-list mt-4">
                            <li><Link to="/easyanalytics" className="text-foot"><i className="mdi mdi-chevron-right mr-1"></i> EasyAnalytics</Link></li>
                            <li><Link to="/faulttracker" className="text-foot"><i className="mdi mdi-chevron-right mr-1"></i> FaultTracker</Link></li>
                            <li><Link to="/rewardbook" className="text-foot"><i className="mdi mdi-chevron-right mr-1"></i> RewardBook</Link></li>
                        </ul>
                    </div>
                    <div className="col-lg-3 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                        <h4 className="text-light footer-head">Contact Details</h4>
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="foot-subscribe form-group position-relative">
                                        <label>Our Email <span className="text-danger">: </span></label>
                                        <p>sailesh@metalclock.in</p>
                                        <p>tech@metalclock.in</p>
                                    </div>
                                </div>
                            </div>

                    </div>
                </div>
            </div>
        </footer>
        <hr />
        <footer className="footer footer-bar">
            <div className="container text-center">
                <div className="row align-items-center">
                    <div className="col-sm-6" >
                        <div className="text-sm-left" style={{display:"flex",justifyContent:"space-between"}}>
                            <p className="mb-0">©  {new Date().getFullYear()}  MetalClock. Developed with 💝</p>
                            <Link to="/">Terms & Condition</Link>
                            <Link to="/privacy">Privacy</Link>
                        </div>
                    </div>
                </div>
            </div>
        </footer>

      </React.Fragment>
    );
  }
}

export default Footer;
