import React from 'react';

// Import all components
const MetalClock = React.lazy(() => import('./pages/MetalClock/index'));
const EasyAnalytics = React.lazy(() => import('./pages/EasyAnalytics/index'));
const RewardBook = React.lazy(() => import('./pages/RewardBook/index'));
const FaultTracker = React.lazy(() => import('./pages/FaultTracker/index'));
const AboutUs = React.lazy(() => import('./pages/AboutUs'));
const WhyMetalClock = React.lazy(() => import('./pages/UseCases')); 
const ContactUs = React.lazy(() => import('./pages/PageContactThree'));
const Terms = React.lazy(() => import('./pages/Terms'));
const Privacy = React.lazy(() => import('./pages/Privacy'));


const routes = [
    // public Routes
    { path: '/easyanalytics', component: EasyAnalytics },
    { path: '/rewardbook', component: RewardBook },
    { path: '/faulttracker', component: FaultTracker },
    { path: '/aboutus', component: AboutUs },
    { path: '/why_metalclock', component: WhyMetalClock},
    {path: '/contactus',component: ContactUs},
    {path:'/privacy',component:Privacy},
    {path:'/terms',component:Terms},
    { path: '/', component: MetalClock },
    

];

export default routes;